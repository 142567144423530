import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CogedimAuthenticationService} from './cogedim-authentication.service';
import {COGIDIM_ROUTES} from '../../constantes/routes';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private router: Router,
                private authenticationService: CogedimAuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.isConnected()) { // utilisateur connecté
            return true;
        }
        this.router.navigate([`/${COGIDIM_ROUTES.Login.route}`]);
        return false; // utilisateur non connecté
    }
}
